.list-item-text {
  font-family: 'Inter', sans-serif;
  padding-left: 18px;
  color: #1579af;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
}

.list-item-text:hover {
  color: skyblue;
}
